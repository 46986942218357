<template>
  <div class="companyProfileAlls">
    <div class="companyProfileAlls1">
      <img src="../../../../assets/img/aboutus/20.jpg" alt="" />
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li v-for="(tab, index) in tabsName" :key="index">
          <span
            class="tab-link"
            @click="tabsSwitch(index)"
            :class="active == index ? 'active' : ''"
            >{{ tab.name }}</span
          >
        </li>
      </ul>
    </div>
    <!-- <div class="companyProfileFirst">
      <div class="companyProfileFirstLeft">
        <h1>关于我们</h1>
        <p class="companyProfileFirstLeft1">
          成都御之安科技有限公司（简称御之安），致力于为广大政企用户提供全链路云安全防护产品和实战化的攻防体系，并通过还原真实攻防来帮助用户构建更安全、更灵动、更智能、更具价值的防御体系。无实战，不安全！御之安成立于2021年，公司位于四川成都市，团队成员来自于各大安全厂商及头部互联网企业安全实验室，拥有多年一线攻防对抗的工作经验，多次在大型攻防演练中名列前茅。
          公司致力于信息安全咨询、信息安全产品服务、信息安全证书认证培训、物联网安全等安全服务，并帮助企业客户及时发现与解决，高素质的安全人才，严格化的保密制度管理，为您提供24小时专业防护，是一家专业的网络安全公司。
        </p>
        <p class="companyProfileFirstLeft2"></p>
      </div>

      <div class="companyProfileFirstRight">ABOUT US</div>
    </div> -->
    <div class="companyProfileFirst d_jump">
      <h1>关于我们</h1>
      <div class="companyProfileFirst1">
        <div class="companyProfileFirst1Left">
          <img src="../../../../assets/img/aboutus/3.webp" alt="" />
        </div>
        <div class="companyProfileFirst1Right">
          成都御之安科技有限公司（简称御之安），致力于为广大政企用户提供全链路云安全防护产品和实战化的攻防体系，并通过还原真实攻防来帮助用户构建更安全、更灵动、更智能、更具价值的防御体系。无实战，不安全！御之安成立于2021年，公司位于四川成都市，团队成员来自于各大安全厂商及头部互联网企业安全实验室，拥有多年一线攻防对抗的工作经验，多次在大型攻防演练中名列前茅。
          公司致力于信息安全咨询、信息安全产品服务、信息安全证书认证培训、物联网安全等安全服务，并帮助企业客户及时发现与解决，高素质的安全人才，严格化的保密制度管理，为您提供24小时专业防护，是一家专业的网络安全公司。
          <div class="companyProfileFirst1Right1">
            <div class="companyProfileFirst1Right2"></div>
            <div class="companyProfileFirst1Right3"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="companyProfileSecond">
      <div class="companyProfileSecond1">
        <div class="companyProfileSecond1-1">
       
          <div class="companyProfileSecond1-1-1">
            <h3>服务领域</h3>
            <p>
              CTF、红蓝对抗、代码审计、渗透测试、应急响应、IoT安全与车联网安全、信息安全咨询、信息安全产品服务、信息安全证书认证培训、物联网安全等安全服务
            </p>
          </div>
        </div>
        <div class="companyProfileSecond1-2">
         
          <div class="companyProfileSecond1-2-1">
            <h3>我们的目标</h3>
            <p>守护国家安全 护航数字经济</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="companyProfileSecond wow fadeInUp">
      <div class="companyProfileSecond1">
        <div class="companyProfileSecond1Left">
          <p>·腾讯安全联合实验室承担“国家重点研发计划” 两大课题</p>
          <p>·公安部授予“公安部打击治理电信网络新型违法犯罪防控中心”</p>
          <p>与国家工商总局共同成立“网络传销深圳监测中心</p>
          <p>与浙江省公安厅共建安全联合实验室</p>
          <p>·携手上海市成立“腾讯上海反电信网络诈骗联合实验室”</p>
          <p>·与重庆市网信办共同成立“腾讯重庆网络大数据实验室”</p>
          <p>
            ·与广州、西安、武汉、北京、上海、山东等地开展校企联合培养网安人才
          </p>
          <p>·2015年开始，每年持续为春节微信红包活动提供安全保障能力</p>
          <p>·发掘微软、谷歌、 Adobe、苹果、宝马等厂商的安全漏洞，获公开致谢</p>
        </div>
        <div class="companyProfileSecond1Right">
          <img src="../../../../assets/img/aboutus/2.webp" alt="" />
        </div>
      </div>
    </div>

    <div class="companyProfileThird d_jump">
      <h1>优势技术</h1>
      <div class="companyProfileThird1">
        <div class="companyProfileThird1Left wow fadeInRight">
          <p @click="companyProfileThird1Lefts(1)">愿景<span v-show="companyProfileThird1RightShow"> 御之安科技是互联网领域的领军企业，在整个行业的数字化转型过程中，都将是一位安全策略官</span></p>
          <p @click="companyProfileThird1Lefts(2)">经验<span v-show="companyProfileThird1RightShow1">10余年经营安全经验和与黑灰产对抗的经验</span></p>
          <p @click="companyProfileThird1Lefts(3)">积累<span v-show="companyProfileThird1RightShow2">依托业内顶尖安全专家，御之安技术研发中心和实验室，与四支顶级联合战队联手</span></p>
          <p @click="companyProfileThird1Lefts(4)">方法论<span v-show="companyProfileThird1RightShow3">以“经验 - 探索 - 架构 - 实施”进行构建行业安全逻辑</span></p>
          <p @click="companyProfileThird1Lefts(5)">实践<span v-show="companyProfileThird1RightShow4">依托业务需要的场景进行实践</span></p>
          <p @click="companyProfileThird1Lefts(6)">目标<span
          v-show="companyProfileThird1RightShow5">守护国家安全，护航数字经济</span></p>
        </div>
        <div class="companyProfileThird1Right wow fadeInLeft">
          <h2>国际一流的御之安科技联合实验室战队矩阵</h2>
          <p>
            依托御之安伴随中国互联网产业发展十多年的安全实践与积累，成就了国内最为完整的企业级安全体系规划、建设、运营及管理的最佳实践，实现了全栈式企业级安全解决方案与安全生态建设，并践行了“云与端之间”的安全管理
          </p>
         <p>
          提供城市级的真实攻防演练场景，为城市安全防御和攻击战术提供真实可靠的情报参考
         </p>
         <p>
          基于真实场景的城市安全攻防训练，提升客户的网上安全行动能力
         </p>
        </div>
      </div>
    </div>
    <div class="companyProfileFourth d_jump">
      <vue-seamless-scroll
        :data="this.hzList"
        :class-option="classOption"
        class="warp"
      >
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in this.hzList" :key="index">
            <img :src="item" alt="" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

    <div class="companyProfileSixth d_jump">
      <h1>新闻资讯</h1>
      <div class="companyProfileSixth1"></div>
      <!-- <div class="companyProfileSixth11">
        <a href="">更多>></a>
      </div> -->
      <div class="companyProfileSixth2">
        <div class="companyProfileSixth2-1">
          <img src="../../../../assets/img/aboutus/1.jpeg" alt="" />
          <p>御炼(CTF竞赛平台)2022成功举行</p>
        </div>
        <div class="companyProfileSixth2-2">
          <div class="companyProfileSixth2-2-1">
            <img src="../../../../assets/img/aboutus/12.jpg" alt="" />
            <p>
              御之安科技荣获“2022年川渝网络与信息安全职业技能竞赛”团队赛初赛第二名。
            </p>
          </div>
          <div class="companyProfileSixth2-2-1">
            <img src="../../../../assets/img/aboutus/11.jpg" alt="" />
            <p>御之安培训讲师,专业团队负责课程及认证试题开发，以及体系搭建。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { WOW } from "wowjs";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      tabsName: [
        {
          name: "公司简介",
          isActive: true,
        },
        {
          name: "优势技术",
          isActive: false,
        },
        {
          name: "资质荣誉",
          isActive: false,
        },
        {
          name: "新闻动态",
          isActive: false,
        },
      ],
      active: 0,
      hzList: [
        require("../../../../assets/img/aboutus/3.jpg"),
        require("../../../../assets/img/aboutus/4.jpg"),
        require("../../../../assets/img/aboutus/5.jpg"),
        require("../../../../assets/img/aboutus/6.jpg"),
        require("../../../../assets/img/aboutus/8.jpg"),
        require("../../../../assets/img/aboutus/9.jpg"),
        require("../../../../assets/img/aboutus/10.jpg"),
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      companyProfileThird1RightShow: true,
      companyProfileThird1RightShow1: false,
      companyProfileThird1RightShow2: false,
      companyProfileThird1RightShow3: false,
      companyProfileThird1RightShow4: false,
      companyProfileThird1RightShow5: false,
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollLength, false);
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  methods: {
    /* 监听滚动(结合前面的@scroll) */
    scrollEvent(e) {
      let scrollItems = document.querySelectorAll(".d_jump");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          this.catalogCur = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.catalogCur = this.articleName.length - 1;
      }

      console.log(e.srcElement.scrollTop); // 滚动条高度
      console.log(e.srcElement.offsetHeight); // 屏幕高度
      console.log(e.srcElement.scrollHeight); // 内容高度
      // '下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
    },
    tabsSwitch(indexs) {
      this.active = indexs;
      let jump = document.querySelectorAll(".d_jump");
      jump[indexs].scrollIntoView({ block: "start", behavior: "smooth" });
    },
    companyProfileThird1Lefts(ids) {
      if (ids === 1) {
        this.companyProfileThird1RightShow = true;
        this.companyProfileThird1RightShow1 = false;
        this.companyProfileThird1RightShow2 = false;
        this.companyProfileThird1RightShow3 = false;
        this.companyProfileThird1RightShow4 = false;
        this.companyProfileThird1RightShow5 = false;
        return false;
      }
      if (ids === 2) {
        this.companyProfileThird1RightShow = false;
        this.companyProfileThird1RightShow1 = true;
        this.companyProfileThird1RightShow2 = false;
        this.companyProfileThird1RightShow3 = false;
        this.companyProfileThird1RightShow4 = false;
        this.companyProfileThird1RightShow5 = false;
        return false;
      }
      if (ids === 3) {
        this.companyProfileThird1RightShow = false;
        this.companyProfileThird1RightShow1 = false;
        this.companyProfileThird1RightShow2 = true;
        this.companyProfileThird1RightShow3 = false;
        this.companyProfileThird1RightShow4 = false;
        this.companyProfileThird1RightShow5 = false;
        return false;
      }
      if (ids === 4) {
        this.companyProfileThird1RightShow = false;
        this.companyProfileThird1RightShow1 = false;
        this.companyProfileThird1RightShow2 = false;
        this.companyProfileThird1RightShow3 = true;
        this.companyProfileThird1RightShow4 = false;
        this.companyProfileThird1RightShow5 = false;
        return false;
      }
      if (ids === 5) {
        this.companyProfileThird1RightShow = false;
        this.companyProfileThird1RightShow1 = false;
        this.companyProfileThird1RightShow2 = false;
        this.companyProfileThird1RightShow3 = false;
        this.companyProfileThird1RightShow4 = true;
        this.companyProfileThird1RightShow5 = false;
        return false;
      }
      if (ids === 6) {
        this.companyProfileThird1RightShow = false;
        this.companyProfileThird1RightShow1 = false;
        this.companyProfileThird1RightShow2 = false;
        this.companyProfileThird1RightShow3 = false;
        this.companyProfileThird1RightShow4 = false;
        this.companyProfileThird1RightShow5 = true;
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.companyProfileAlls {
  width: 100%;
  .companyProfileAlls1 {
    img {
      width: 100%;
      height: 500px;
    }
  }
  .box {
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
  }
  .tabs {
    display: flex;
    justify-content: center;

    height: 95px;
    border-bottom: 1px solid #dadada;
  }
  .tabs li {
    float: left;
    margin-right: 27px;
    list-style: none;
  }

  .tabs .tab-link {
    display: block;
    width: 260px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    font-size: 20px;
    color: #000;
    text-decoration: none;
  }
  .tabs .tab-link:hover {
    cursor: pointer;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }
  .tabs .tab-link.active {
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }

  .card {
    width: 100%;
    margin: 0 auto;
  }

  .card .cards {
    display: none;
  }

  .clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }

  // .companyProfileFirst {
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-end;
  //   margin-top: 80px;
  //   .companyProfileFirstLeft {
  //     width: 30%;
  //     height: 300px;
  //     padding: 20px;
  //     .companyProfileFirstLeft1 {
  //       font-size: 16px;
  //       color: #51565d;
  //       margin-top: 20px;
  //       line-height: 32px;
  //     }
  //     .companyProfileFirstLeft2 {
  //       border-bottom: 5px solid #ccc;
  //       width: 50px;
  //       padding-top: 50px;
  //     }
  //   }
  //   .companyProfileFirstRight {
  //     width: 500px;
  //     height: 100px;
  //     padding: 20px;
  //     font-size: 24px;
  //     color: #51565d;
  //     background: #f1f5ff;
  //     transform: rotate(270deg);
  //   }
  // }
  .companyProfileFirst {
    padding: 30px;
    margin-top: 40px;
    h1 {
      text-align: center;
    }
    .companyProfileFirst1 {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      // .companyProfileFirst1Left{
      //   width: 35%;
      //   img{
      //     width: 600px;
      //   }
      // }
      .companyProfileFirst1Right {
        width: 30%;
        padding: 20px;
        text-indent: 2rem;
        font-size: 18px;
        font-weight: 200;
        line-height: 25px;
        margin-left: 30px;
        box-shadow: 0px 2px 3px 0px rgb(130, 127, 127);
        .companyProfileFirst1Right1 {
          display: flex;
          padding-top: 30px;
          .companyProfileFirst1Right2 {
            width: 50%;
            border-top: 3px solid #4388c9;
          }
          .companyProfileFirst1Right3 {
            width: 50%;
            border-top: 3px solid #7fcbf6;
          }
        }
      }
    }
  }
  .companyProfileSecond {
    margin-top: 50px;
    padding: 30px;
    background: #f5f8ff;
    .companyProfileSecond1 {
      padding: 60px;
      display: flex;
      justify-content: center;
        align-items: center;
      .companyProfileSecond1Left {
        width: 30%;
        p {
          opacity: 0.8;
          font-size: 18px;
          margin-top: 10px;
        }
      }
      .companyProfileSecond1Right{
        margin-left: 30px;
      }
    }
  }
  // .companyProfileSecond {
  //   margin-top: 260px;
  //   background-image: url(../../../../assets/img/aboutus/1.jpg);
  //   background-repeat: no-repeat;
  //   background-size: 900px 700px;
  //   height: 500px;
  //   width: 100%;
  //   overflow-x: hidden;
  //   position: relative;
  //   .companyProfileSecond1 {
  //     margin-left: 30px;
  //     position: absolute;
  //     flex: 1;
  //     display: flex;
  //     left: 25%;
  //     top: 10%;
  //     width: 100%;
  //     align-items: flex-start;
  //     background-color: #000;
  //     padding: 30px;
  //     height: 400px;
  //     .companyProfileSecond1-1 {
  //       color: #fff;
  //       line-height: 26px;
  //       padding: 20px;
  //       display: flex;
  //       width: 300px;
  //       img {
  //         width: 50px;
  //         height: 50px;
  //       }
  //       h3 {
  //         color: #025db3;
  //         font-weight: 800;
  //       }
  //     }
  //     .companyProfileSecond1-2 {
  //       font-size: 16px;
  //       color: #fff;
  //       display: flex;
  //       margin-left: 70px;
  //       line-height: 26px;
  //       padding: 20px;
  //       border-radius: 5px;
  //       width: 200px;
  //       h3 {
  //         color: #025db3;
  //         font-weight: 800;
  //       }
  //       img {
  //         width: 50px;
  //         height: 50px;
  //       }
  //     }
  //   }
  // }
  // .companyProfileThird {
  //   margin-top: 50px;
  //   h1 {
  //     text-align: center;
  //   }
  //   .companyProfileThird1 {
  //     width: 50px;
  //     margin: 0 auto;
  //     border-bottom: 5px solid #ccc;
  //   }
  //   .companyProfileThird2 {
  //     display: flex;
  //     justify-content: center;
  //     .companyProfileThird2-1 {
  //       margin-top: 20px;
  //       width: 380px;
  //       background-image: url(../../../../assets/img/aboutus/3.png);
  //       background-repeat: no-repeat;
  //       background-size: 300px 340px;
  //       padding: 20px;
  //       .companyProfileThird2-1-1 {
  //         display: flex;
  //         align-items: center;
  //         img {
  //           width: 100px;
  //           height: 100px;
  //         }
  //         .companyProfileThird2-1-1-1 {
  //           h3 {
  //             color: #47fbff;
  //           }
  //           p {
  //             font-size: 12px;
  //             color: #616161;
  //           }
  //         }
  //       }
  //       .companyProfileThird2-1-2 {
  //         width: 264px;
  //         text-align: center;
  //         padding-top: 20px;
  //         color: #47fbff;
  //       }
  //       ul {
  //         width: 264px;
  //         padding-top: 10px;
  //         li {
  //           list-style-type: none;
  //           color: #fff;
  //         }
  //       }
  //     }
  //   }
  // }
  .companyProfileThird {
    margin-top: 40px;
    padding: 30px;
    background: #f5f8ff;
    // background-image: url(../../../../assets/img/aboutus/21.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
    .companyProfileThird1 {
      display: flex;
      justify-content: center;
      .companyProfileThird1Left {
        width: 30%;
        background: #fff;
        p {
          cursor: pointer;
          font-size: 16px;
          font-weight: 200;
          padding: 20px;
          margin-top: 10px;
          background-color: #a4cdcb;
        }
        p:hover {
          background: #fff;
        }
        span{
          padding-left: 30px;
        }
      }
      .companyProfileThird1Right {
        width: 40%;
        background: #fff;
        opacity: 0.5;
        padding: 20px;
        margin-left: 30px;
        p {
          font-size: 22px;
          padding-top: 10px;
        }
      }
    }
  }
  .companyProfileFourth {
    margin-top: 40px;
    .warp {
      width: 326px * 4;
      height: 294px;
      margin: 40px auto;
      overflow: hidden;
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        &.ul-item {
          display: flex;
          .li-item {
            width: 316px;
            height: 294px;
            margin-right: 50px;
            line-height: 294px;
            color: #fff;
            text-align: center;
            font-size: 1.875rem;
            img {
              width: 288px;
              height: 294px;
            }
          }
        }
      }
    }
  }
  // .companyProfileFourth {
  //   margin-top: 50px;
  //   height: 500px;
  //   background-image: url(../../../../assets/img/aboutus/2.jpg);
  //   background-repeat: no-repeat;
  //   background-size: 100% 500px;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: flex-end;
  //   .companyProfileFourth1 {
  //     width: 1000px;
  //     opacity: 0.8;
  //     background-color: #fff;
  //     height: 400px;
  //     font-size: 18px;
  //     padding: 100px;
  //   }
  // }
  .companyProfileFiveth {
    .companyProfileFiveth1 {
      margin-top: 40px;
      background-color: #1d1d1d;
      min-height: 160vh;
      position: relative;
      font-family: "Droid Sans", sans-serif;
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        width: 4px;
        background-color: #fff;
        h1 {
          text-align: center;
        }
      }
    }

    .entries {
      width: calc(100% - 80px);
      max-width: 800px;
      margin: auto;
      position: relative;
      left: -5px;
      .entry {
        width: calc(50% - 80px);
        height: 200px;
        float: left;
        padding: 20px;
        clear: both;
        text-align: right;
        &:not(:first-child) {
          margin-top: -60px;
        }
        .title {
          font-size: 32px;
          margin-bottom: 12px;
          position: relative;
          color: #fff;
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border: 4px solid #ffffff;
            background-color: #1d1d1d;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: -113px;
            z-index: 1000;
          }
          &.big:before {
            width: 24px;
            height: 24px;
            transform: translate(8px, -50%);
          }
        }
        .body {
          color: #aaa;
          p {
            line-height: 1.4em;
          }
        }
        &:nth-child(2n) {
          text-align: left;
          float: right;
          .title {
            &:before {
              left: -102px;
            }
            &.big:before {
              transform: translate(-8px, -50%);
            }
          }
        }
      }
    }
  }
  .companyProfileSixth {
    margin: 100px 0;
    width: 100%;
    h1 {
      text-align: center;
    }
    .companyProfileSixth1 {
      width: 50px;
      margin: 0 auto;
      border-bottom: 5px solid #ccc;
    }
    .companyProfileSixth11 {
      width: 80%;
      text-align: right;
    }

    .companyProfileSixth2 {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .companyProfileSixth2-1 {
        display: flex;
        flex-direction: column;
        img {
          width: 272px;
          height: 210px;
        }
        p {
          margin-top: 30px;
          font-size: 18px;
        }
      }
      .companyProfileSixth2-2 {
        box-shadow: 0px 8px 8px 0px rgb(130, 127, 127);
        padding: 10px 20px;
        margin-left: 20px;
        .companyProfileSixth2-2-1 {
          padding: 10px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #ccc;
          img {
            width: 160px;
            height: 110px;
          }
          p {
            width: 300px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>